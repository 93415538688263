export default {
    props: {
        idevento: String,
    },
    data() {
        return {
            dataApre: null,
            dataHorario: '',
            menu: false,
            horarios_: [],
            horarios: [],
            datas: [],
            datas_: [],
            pickerDate: '',
            idapresentacao: '',
            periodo: null,
            multiple: false,
            qtd: 0,
            disabledMinus: false,
            disabledAdd: false,
            carregando: false,
        }
    },
    computed: {
        dateFormated() {
            if (this.dataApre) {
                if (typeof this.dataApre == 'object') {

                    let data = []

                    this.dataApre.forEach(item => {
                        data.push(this.$moment(item).format('DD/MM/YYYY'))
                    })

                    return data.join(' e ')

                }

                return this.$moment(this.dataApre).format('DD/MM/YYYY')
            }
            return null
        }
    },
    watch: {
        '$superingresso.apresentacoes'(e) {
            e.dates.forEach(item => {
                this.datas.push(item.dia)
            })
            this.datas_ = this.datas
            this.dataApre = this.datas[0]
            this.getHorarios(e)
        },
        periodo(e) {

            if (!this.$axios.getLocal('session') && !this.$config.dados.carrinhoLocal) {
                this.$usuario.showModalLogin = true
                return;
            }

            if (e.indexOf('[2') != -1) {
                this.multiple = true
            } else {
                this.multiple = false
            }

            this.datas = this.datas_
            this.pickerDate = this.$moment(this.datas[1]).format('YYYY-MM')

            this.menu = !this.menu
            this.dataApre = undefined
            this.dataHorario = undefined
        },
        dataApre(val, old) {

            this.horarios_ = []
            this.carregando = false

            if (val && (this.periodo)) {

                this.horarios_ = this.horarios.find(item => item.id == val)
                this.dataHorario = null
                this.carregando = true

                if (!this.dataHorario && this.horarios_ && this.horarios_.length > 0) {
                    let autocompleteInput = this.$refs.dataHorario
                    autocompleteInput.focus();
                    autocompleteInput.activateMenu();
                }

            }
            else {
                this.getApre(val)
            }

            //  2 DATAS PARA SELECIONAR
            if (typeof val == 'object') {

                this.horarios_ = this.horarios.find(item => item.id == val[0])
                this.dataHorario = null
                this.carregando = true

                if (val.length < 2) {
                    this.datas = this.datas_
                }

                if (val.length >= 2) {

                    if (val.length > 2) {
                        val.shift()
                    }

                    let data1 = new Date(val[0])
                    let data2 = new Date(val[1])

                    if (data1 > data2) {
                        val.shift()
                        this.datas = this.datas_
                        return
                    }

                }

                if (val.length != 0) {

                    let sete = 0
                    let selectsdates = []

                    this.datas.forEach((item, index) => {
                        if (item == val[0]) {
                            sete = 1
                        }

                        if (sete > 0 && selectsdates.length < 7) {
                            selectsdates.push(item)
                            sete++
                        }
                    })

                    this.datas = selectsdates
                }

                if (val.length == 2) {
                    this.menu = !this.menu
                    this.getApre(val[0], true)
                }

            }

            if (this.horarios_ && this.horarios_.itens && this.horarios_.itens.length == 1) {
                this.dataHorario = this.horarios_.itens[0]
            }


        },

        async dataHorario(e) {

            if (e) {

                // this.$carrinho.AddPedido = 
                await this.getProdutos(e.idapresentacao)



                // RECARREGA OS ITENS PARA SER EXIBIDOS A QUANTIDADE RESERVADA

                if (this.$axios.getLocal('local_carrinho') && this.$axios.getLocal('local_carrinho').data.length > 0) {
                    let car = this.$axios.getLocal('local_carrinho').data
                    let cc = this.$carrinho.AddPedido.tipo_produtos;

                    cc = cc.find(item => item.dsctipoproduto == this.periodo)
                    car = car.find(item => (item.dt_apre == this.dataApre || item.dt_apre == this.dataApre + ' ' + e.hora + ':00') && item.idapresentacao == e.idapresentacao)

                    cc.produtos.find(p => {
                        p.qtd = 0
                    })

                    if (cc && car && car.produtos) {
                        car.produtos.forEach(item => {
                            cc.produtos.find(p => {
                                if (p.idproduto == item.idproduto) {
                                    p.qtd = item.qtd
                                }
                            })
                        })
                    }

                }

                if (this.$axios.getLocal('carrinho') && this.$axios.getLocal('carrinho').data.length > 0) {
                    let car = this.$axios.getLocal('carrinho').data;
                    let cc = this.$carrinho.AddPedido.tipo_produtos;

                    cc = cc.find(item => item.dsctipoproduto == this.periodo)
                    car = car.find(item => (item.dt_apre == this.dataApre || item.dt_apre == this.dataApre + ' ' + e.hora + ':00') && item.idapresentacao == e.idapresentacao)

                    cc.produtos.find(p => {
                        p.qtd = 0
                    })

                    if (cc && car && car.produtos) {
                        car.produtos.forEach(item => {
                            cc.produtos.find(p => {
                                if (p.idproduto == item.idproduto) {
                                    p.qtd = item.qtd
                                }
                            })
                        })
                    }

                }

            }
        }

    },
    methods: {
        clearHorario() {
            let autocompleteInput = this.$refs.dataHorario

            if (autocompleteInput && autocompleteInput.label != '') {
                autocompleteInput.blur();
            }
        },
        openClock() {
            this.$refs['dataHorario'].onClick()
        },
        disabledButton(item) {
            this.disabledMinus = true
            this.disabledAdd = true
            const self = this
            setTimeout(() => {
                self.disabledMinus = false
                self.disabledAdd = false
            }, 1500)
        },
        allowedDates(val) {
            return this.datas.indexOf(val) !== -1
        },
        save(date) {
            this.$refs.menu.save(date)
        },

        getHorarios(e) {

            // HORARIOS

            this.carregando = true

            if (this.$axios.getLocal('lang')) {
                this.$moment.locale(this.$axios.getLocal('lang').data);
            } else {
                this.$moment.locale('pt-BR');
            }

            let apre = e.apresentacoes
            let horarios = []
            let obj = []
            let obj_ = []

            apre.forEach(item => {
                //if(item.nidregra == "" || item.nidregra == null){
                horarios.push({ id: this.$moment(item.dthr_apresentacao).format('YYYY-MM-DD'), idapresentacao: item.idapresentacao, hora: this.$moment(item.dthr_apresentacao).format('LT') })
                //}
            })

            obj = horarios.filter(function (item, index, array) {
                return !this[JSON.stringify(array[index].id)] && (this[JSON.stringify(item.id)] = true);
            }, Object.create(null))

            obj.forEach(item => {
                let primal = {
                    id: item.id,
                    itens: []
                }
                horarios.forEach((i, index, array) => {
                    if (i.id == item.id) {

                        // FAZ O TRATAMENTO PARA QUE SÓ PEGE OS HORÁRIOS DO DIA

                        if (i.id == this.$moment().format('YYYY-MM-DD')) {
                            if (i.hora > this.$moment().format('LT')) {
                                if (index - 1 > 0) {
                                    primal.itens.push({ idapresentacao: array[index - 1].idapresentacao, hora: array[index - 1].hora }) // COLOCA A HORA vencida
                                }
                                primal.itens.push({ idapresentacao: i.idapresentacao, hora: i.hora })
                            }
                        }

                        if (i.id != this.$moment().format('YYYY-MM-DD')) {
                            primal.itens.push({ idapresentacao: i.idapresentacao, hora: i.hora })
                        }

                    }
                })
                obj_.push(primal)
            })

            this.horarios = obj_
        },

        async getProdutos(idApresentacao) {
            const root = document.getElementById('app').__vue__
            this.$carrinho.AddPedido = []
            await this.$superingresso.getTipoProdutos(idApresentacao)
                .then((response) => {

                    if (response && response.tipo_produtos) {

                        let tipo_produtos = response
                        let periodo = []
                        tipo_produtos.tipo_produtos.forEach(item_temp => {
                            periodo.push({ dsctipoproduto: item_temp.dsctipoproduto, idtipoproduto: item_temp.idtipoproduto })
                            item_temp.produtos.forEach(item_temp2 => {
                                item_temp2.disabledAdd = false
                                item_temp2.disabledMinus = false
                                item_temp2.qtd = 0
                            })
                        })

                        // MERGE OBJETOS
                        if (this.$axios.getLocal('carrinho') && this.$axios.getLocal('carrinho').data || this.$carrinho.carrinho) {
                            let carrinho = this.$axios.getLocal('carrinho').data || this.$carrinho.carrinho;
                            carrinho.forEach(car => {
                                if (car.idapresentacao === idApresentacao) {
                                    car.produtos.forEach(prod => {
                                        tipo_produtos.tipo_produtos.forEach(item_temp => {
                                            item_temp.produtos.forEach(item_temp2 => {
                                                if (item_temp2.idproduto == prod.idproduto) {
                                                    item_temp2.qtd += 1
                                                }
                                            })
                                        })
                                    })
                                }
                            })

                        }

                        periodo = periodo.filter(function (item, index, array) {
                            return !this[JSON.stringify(array[index].idtipoproduto)] && (this[JSON.stringify(item.idtipoproduto)] = true);
                        }, Object.create(null))


                        tipo_produtos.periodo = periodo

                        tipo_produtos.idapresentacao = idApresentacao
                        this.$carrinho.AddPedido = tipo_produtos


                    }
                })
        },

        async getApre(val) {

            this.$superingresso.apresentacoes.apresentacoes.every(item => {
                if (this.$moment(val).format('YYYYMMDD') == this.$moment(item.dthr_apresentacao).format('YYYYMMDD')) {
                    this.dataHorario = undefined
                    this.getProdutos(item.idapresentacao)
                    this.$carrinho.atualizaCarrinho()
                    return false
                }
                else return true
            })

        }
    },
    mounted() {
        const self = this
        let time = setInterval(() => {
            if (self.idevento) {
                clearInterval(time)
                self.$superingresso.getApresentacoes(self.idevento);
            };
        }, 1000)

    }
}