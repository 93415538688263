<template>
  <div style="width: 100%">
    <div class="checkout-container05">
      <div class="checkout-container06">
        <div
          class="checkout-container07"
          :style="'background-color:' + $config.dados.corprimaria"
        >
          <h1 class="checkout-text02">
            {{ typeof car.dt_apre == "string" ? car.dt_apre : car.dt_apre[0] | dia }}
          </h1>
          <span class="checkout-text03">
            <span class="checkout-text04">{{
              typeof car.dt_apre == "string" ? car.dt_apre : car.dt_apre[0] | mesano
            }}</span>
          </span>
        </div>
        <div class="checkout-container08">
          <h1 class="checkout-text06" :style="'color:' + $config.dados.corprimaria">
            {{ typeof car.dscapre == "string" ? car.dscapre : car.dscapre[0] }}
          </h1>
        </div>
        <div class="checkout-container09">
          <div class="checkout-container10" v-if="$axios.getLocal('session')">
            <v-btn
              icon
              class="checkout-icon03"
              @click="$carrinho.cancelarReservaDocarrinho(car)"
            >
              <v-icon color="#cdcdcd">mdi-trash-can</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <template>
        <div
          class="checkout-container11"
          v-for="(item, index) in !$axios.getLocal('session')
            ? car.produtos
            : car.cancelar"
          :key="index"
        >
          <div class="checkout-container12">
            <div class="checkout-text09">
              <strong v-if="item.tpproduto">{{ item.tpproduto }} - </strong>
              {{ item.dscproduto }}
            </div>
            <div class="modal-produtos-container10" v-if="!$axios.getLocal('session')">
              <div
                class="modal-produtos-container11"
                v-if="
                  item.valor_sem_desconto > 0 &&
                  $config.dados.mostrarDescontoCombo &&
                  item.valor_sem_desconto != item.valor
                "
              >
                <span class="modal-produtos-text03-1" :style="'color: #AAAAAA'"
                  >{{ $t_("PRODUTO_DE") }} {{ item.valor_sem_desconto | moeda }}</span
                >
                <span class="modal-produtos-text04-1" :style="'color: #AAAAAA'"
                  >,{{ item.valor_sem_desconto | centavos }}</span
                >
              </div>
              <div
                class="modal-produtos-container11"
                v-if="
                  item.valor_sem_desconto > 0 &&
                  $config.dados.mostrarDescontoCombo &&
                  item.valor_sem_desconto != item.valor
                "
              >
                <span
                  class="modal-produtos-text03"
                  :style="'color:' + $config.dados.corprimaria"
                  >{{ $t_("PRODUTO_POR") }} {{ item.preco | moeda }}</span
                >
                <span
                  class="modal-produtos-text04"
                  :style="'color:' + $config.dados.corprimaria"
                  >,{{ item.preco | centavos }}</span
                >
              </div>
              <div class="modal-produtos-container11" v-else>
                <span
                  class="modal-produtos-text03"
                  :style="'color:' + $config.dados.corprimaria"
                  >{{ item.preco | moeda }}</span
                >
                <span
                  class="modal-produtos-text04"
                  :style="'color:' + $config.dados.corprimaria"
                  >,{{ item.preco | centavos }}</span
                >
              </div>
            </div>
            <div>
              <strong
                ><small style="text-transform: uppercase">{{
                  item.dscproduto
                }}</small></strong
              >
            </div>
            <div>
              <strong v-if="item.dt_hora"
                ><small>{{ $t_("RESERVADO_PARA") }} {{ item.dt_hora }}</small></strong
              >
              <strong v-else-if="item.dt_apre">
                <small
                  >{{ $t_("reservado") }}
                  {{
                    typeof car.dt_apre == "string"
                      ? $moment(car.dt_apre).format("HH:mm")
                      : $moment(car.dt_apre[0]).format("HH:mm")
                  }}</small
                >
              </strong>
              <strong v-else></strong>
            </div>
          </div>
          <div class="checkout-container-portador">
            <template v-if="item.portador && item.portador[0].idregra">
              <div v-if="item.portador[0].nome && $axios.getLocal('session')">
                <div class="d-md-flex align-center justify-space-around pa-0 ma-0 ml-2">
                  <span> {{ $t_("portador_cadastrado") + item.portador[0].nome }} </span>
                  <v-btn
                    elevation="0"
                    fab
                    color="red"
                    height="20"
                    width="20"
                    class="cancel-button white--text font-weight-bold mr-2 ml-2"
                    @click="addPortador(item)"
                    >x</v-btn
                  >
                </div>
              </div>
              <div v-else-if="$axios.getLocal('session')">
                <v-btn
                  :color="$config.dados.corprimaria"
                  class="white--text"
                  small
                  @click="addPortador(item)"
                  >{{ $t_("portador_2") }}</v-btn
                >
              </div>
            </template>
          </div>
          <div class="checkout-container15">
            <div class="checkout-container16" v-if="!$axios.getLocal('session')">
              <!-- <div class="checkout-container17">
                                <div class="checkout-container18" :style="'background-color:' + $config.dados.corprimaria" @click="$carrinho.minus(item, { idapresentacao: car.idapresentacao, dt_apre: car.dt_apre, dscapre: car.dscapre }, true)">
                                    <svg viewBox="0 0 804.5714285714286 1024" class="checkout-icon05">
                                        <path
                                            d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-694.857c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h694.857c30.286 0 54.857 24.571 54.857 54.857z">
                                        </path>
                                    </svg>
                                </div>
                            </div> -->
              <!-- <div class="checkout-container19">
                                <big :style="'color:' + $config.dados.corprimaria">{{ item.qtd }}</big>
                            </div> -->
              <!-- <div class="checkout-container20">
                                <div class="checkout-container21" :style="'background-color:' + $config.dados.corprimaria" @click="$carrinho.add(item, { idapresentacao: car.idapresentacao, dt_apre: car.dt_apre, dscapre: car.dscapre }, true)">
                                    <svg viewBox="0 0 804.5714285714286 1024" class="checkout-icon07">
                                        <path
                                            d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z">
                                        </path>
                                    </svg>
                                </div>
                            </div> -->

              <v-select
                hide-selected
                v-model="item.qtd"
                :items="qtdSelect(item.qtd)"
                class="rounded-pill bg-corquarternaria pa-0 app-qtd"
                hide-details
                dense
                outlined
                style="width: 100px; height: 35px"
                @click="
                  (manual = item),
                    (manual_tipo_produtos = car.produtos || car.cancelar),
                    (chave = index)
                "
                @input="
                  selecao(item, {
                    idapresentacao: car.idapresentacao,
                    dt_apre: car.dt_apre,
                    dscapre: car.dscapre,
                  })
                "
              >
              </v-select>
            </div>

            <v-expansion-panels
              v-if="item.prodhelp"
              v-model="item.ajuda_aberta"
              class="elevation-0 mb-2"
            >
              <v-expansion-panel
                dense
                v-for="(itemProd, i) in [item]"
                :key="i"
                class="elevation-0 pa-0 ma-0"
                :style="
                  'background-color:' +
                  $config.dados.corsecundaria +
                  ';color:' +
                  $config.dados.corprimaria
                "
                style="margin: 0px; border-radius: 5px"
              >
              </v-expansion-panel>
            </v-expansion-panels>

            <div v-else>
              <div class="checkout-container10" v-if="$axios.getLocal('session')">
                <v-btn
                  icon
                  class="checkout-icon03"
                  @click="
                    $carrinho.cancelarReservaDocarrinho({
                      cancelar: [item],
                      idmovimentacao: car.idmovimentacao,
                    })
                  "
                >
                  <v-icon color="#cdcdcd">mdi-trash-can</v-icon>
                </v-btn>
              </div>

              <template v-if="$axios.getLocal('session')">
                <div
                  class="modal-produtos-container1111"
                  v-if="
                    item.valor_sem_desconto > 0 &&
                    $config.dados.mostrarDescontoCombo &&
                    item.valor_sem_desconto != item.valor
                  "
                >
                  <span class="modal-produtos-text03-1" :style="'color: #AAAAAA'"
                    >{{ $t_("PRODUTO_DE") }}{{ item.valor_sem_desconto | moeda }}</span
                  >
                  <span class="modal-produtos-text04-1" :style="'color: #AAAAAA'"
                    >,{{ item.valor_sem_desconto | centavos }}</span
                  >
                </div>
                <div
                  class="modal-produtos-container1111"
                  v-if="
                    item.valor_sem_desconto > 0 &&
                    $config.dados.mostrarDescontoCombo &&
                    item.valor_sem_desconto != item.valor
                  "
                >
                  <span
                    class="modal-produtos-text0333"
                    :style="'color:' + $config.dados.corprimaria"
                    >{{ $t_("PRODUTO_POR") }} {{ item.valor | moeda }}</span
                  >
                  <span
                    class="modal-produtos-text04"
                    :style="'color:' + $config.dados.corprimaria"
                    >,{{ item.valor | centavos }}</span
                  >
                </div>
                <div class="modal-produtos-container1111" v-else>
                  <span
                    class="modal-produtos-text0333"
                    :style="'color:' + $config.dados.corprimaria"
                    >{{ item.valor | moeda }}</span
                  >
                  <span
                    class="modal-produtos-text04"
                    :style="'color:' + $config.dados.corprimaria"
                    >,{{ item.valor | centavos }}</span
                  >
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="$axios.getLocal('session')">
        <span
          v-if="!showAddMore"
          class="checkout-text18"
          @click="getMoreProducts(car.produtos[0])"
        >
          <!-- <span :style="'color:' + $config.dados.corprimaria" >{{$t_('txt_adicionar_remover_ingressos')}}</span> -->
        </span>
        <template v-else>
          <div
            class="checkout-container05-prod"
            v-for="(tipo_produtos, x) in produtosMore.tipo_produtos"
            :key="x + tipo_produtos.dsctipoproduto"
          >
            <div class="checkout-container06">
              <div class="checkout-container08">
                <h3
                  class="checkout-text06-prod"
                  :style="'color:' + $config.dados.corprimaria"
                >
                  {{ tipo_produtos.dsctipoproduto }}
                </h3>
              </div>
            </div>
            <div
              class="checkout-container11"
              v-for="(item, index) in tipo_produtos.produtos"
              :key="index"
            >
              <div class="checkout-container12">
                <span class="checkout-text09">{{ item.dscproduto }}</span>
                <div class="checkout-container14">
                  <span
                    class="checkout-text14"
                    :style="'color:' + $config.dados.corprimaria"
                    >{{ item.preco | moeda }}</span
                  >
                  <span
                    class="checkout-text15"
                    :style="'color:' + $config.dados.corprimaria"
                    >,{{ item.preco | centavos }}</span
                  >
                </div>
              </div>
              <div class="checkout-container15-more">
                <v-select
                  v-model="item.qtd"
                  :items="qtdSelect(item)"
                  class="rounded-pill bg-corquarternaria pa-0 app-qtd"
                  hide-details
                  dense
                  outlined
                  style="width: 85px; height: 35px"
                  @input="
                    selecao(item, {
                      idapresentacao: car.idapresentacao,
                      dt_apre: car.dt_apre,
                      dscapre: car.dscapre,
                    })
                  "
                >
                </v-select>
              </div>
              <div class="checkout-container09">
                <div
                  class="checkout-container10"
                  v-if="$axios.getLocal('session') && item.qtd > 0"
                >
                  <v-btn
                    icon
                    class="checkout-icon03"
                    @click="$carrinho.cancelarReservaItem(car, item)"
                  >
                    <v-icon color="#cdcdcd">mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loadMore" class="checkout-text18-close">
            <v-progress-circular
              indeterminate
              :color="$config.dados.corprimaria"
            ></v-progress-circular>
          </div>
          <span v-else class="checkout-text18-close" @click="showAddMore = false">
            <span :style="'color:' + $config.dados.corprimaria">{{ $t_("fechar") }}</span>
          </span>
        </template>
      </template>
    </div>
    <v-dialog
      v-model="portador.show"
      max-width="800"
      persistent
      :fullscreen="$config.mobile()"
      class="d-flex align-center justify-space-around pa-0 ma-0"
    >
      <v-card
        style="overflow: hidden"
        class="d-flex align-center justify-space-around pa-0 ma-0"
      >
        <v-card-text>
          <v-icon @click="fecharModalPortador()" class="fechar"
            >mdi-close mdi-color-black</v-icon
          >
          <v-row justify="center" align="center" class="ma-0">
            <v-col cols="12" md="4">
              <label required>
                {{ $t_("nome") }}
              </label>
              <v-tooltip top right color="yellow lighten-5 black--text">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" :color="$config.dados.corsecundaria"
                    >mdi-information</v-icon
                  >
                </template>
                <span v-html="$t_('info_portador')"></span>
              </v-tooltip>
              <v-text-field
                class="mt-3"
                v-model="portador.nome"
                type="text"
                dense
                outlined
                hide-details
                tabindex="1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <label required>{{ $t_("documento_field") }}</label>
              <v-text-field
                class="mt-3"
                v-model="portador.documento"
                type="text"
                dense
                outlined
                hide-details
                tabindex="2"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-btn
                :disabled="
                  portador.nome.length != null &&
                  portador.documento != null &&
                  portador.nome.length == 0 &&
                  portador.documento.length == 0
                "
                class="mt-7 font-weight-bold white--text"
                @click="setPortador()"
                :style="'background-color: ' + $config.dados.corprimaria"
                v-html="$t_('associar_portador')"
                block
                elevation="0"
              ></v-btn>
            </v-col>

            <v-col cols="12">
              <v-btn
                text
                class="font-weight-bold black--text"
                @click="setPortador_()"
                :style="'background-color:' + $config.dados.corsecundaria"
                v-html="$t_('usar_cadastro')"
                block
                elevation="0"
              ></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="manual_show">
      <v-card>
        <v-card-title class="txt-add-manual">
          {{ $t_("qtd_ingressos") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            autofocus
            v-on:keyup.enter="addmanualm()"
            v-model="manual.qtd"
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addmanualm()">Atualizar carrinho</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MIXES from "@/mixeds/produto.mixeds.js";

export default {
  name: "CardProdutos",
  props: ["car", "protect"],
  mixins: [MIXES],

  data() {
    return {
      portador: { nome: "", show: false, item: "" },
      portador_: { nome: "", show: false, item: "" },
      produtosMore: [],
      showAddMore: false,

      manual: [],
      manual_show: false,
      manual_tipo_produtos: [],
      chave: 0,
      qtd: [],
    };
  },

  watch: {
    "$carrinho.carrinho"(e) {
      this.atualizaResumo();
    },
    "protect.quote_protect_group.sold"(e) {
      this.atualizaResumo({
        extras_response_front: { quote_protect_group: this.protect.quote_protect_group },
      });
      const time = setInterval(() => {
        if (window.grecaptcha) {
          clearInterval(time);
          window.grecaptcha.execute();
        }
      }, 1000);
    },
    manual(e) {
      if (e && e.qtd == "+mais") {
        e.qtd = 0;
      }
    },
  },

  methods: {
    addmanualm() {
      this.manual_show = false;
      this.manual.qtd =
        this.manual == "900" || this.manual.qtd == null ? 0 : this.manual.qtd;

      this.$carrinho.selecao(
        this.manual,
        {
          idapresentacao: this.dataHorario
            ? this.dataHorario.idapresentacao
            : this.$carrinho.AddPedido.idapresentacao,
          dt_apre: this.dataApre,
          dt_hora: this.dataHorario,
          dscapre: this.manual_tipo_produtos.dsctipoproduto,
        },
        false
      );
    },
    selecao(item, apre) {
      this.$carrinho.selecao(item, apre, true);
    },

    qtdSelect(item) {
      let qtd = 10;

      const res = [0];

      for (let x = 0; x < qtd; x++) {
        res.push(x + 1);
      }

      item ? res.push(item) : null;

      res.push("+mais");
      return res;
    },

    atualizaResumo(protect = false) {
      this.showAddMore = false;
      this.$config.gateway = [];
      this.$config.gatewaySelected = [];

      if (this.$axios.getLocal("session") && this.$carrinho.carrinho.length > 0) {
        let temPortador = 0;

        this.$carrinho.carrinho.forEach((car) => {
          car.cancelar.forEach((item) => {
            if (item.portador && item.portador[0].idregra) {
              temPortador++;
            }
            if (item.portador && item.portador[0].documento) {
              temPortador--;
            }
          });
        });

        if (temPortador == 0) {
          this.$carrinho.getFormaspagamento(protect);
        }
      }
    },

    addPortador(item) {
      this.portador.nome = "";
      this.portador.item = "";
      this.portador.documento = "";
      this.portador.show = true;
      this.portador.item = item;
    },
    setPortador() {
      this.portador.show = false;
      this.$superingresso
        .setPortadorItem([
          { nome: this.portador.nome, documento: this.portador.documento },
          this.portador.item.iditem,
        ])
        .then(() => {
          this.portador = this.portador_;
          this.$carrinho.atualizaCarrinho();
        });
    },
    setPortador_() {
      this.portador.show = false;

      let perfil = this.$axios.getLocal("session").data;
      let dados = {
        nome: this.getPortador(),
        documento: "",
      };

      if (perfil.empresa_cnpj) {
        dados.documento = perfil.empresa_cnpj;
      }

      if (perfil.cpf) {
        dados.documento = perfil.cpf;
      }

      if (perfil.passaporte) {
        dados.documento = perfil.passaporte;
      }

      this.$superingresso.setPortadorItem([dados, this.portador.item.iditem]).then(() => {
        this.portador = this.portador_;
        this.$carrinho.atualizaCarrinho();
      });
    },
    getPortador() {
      return this.$axios.getLocal("session").data.nome;
    },
    validaPortador(nome, documento) {
      if (nome.length < 3) {
        return true;
      } else if (documento.length < 5) {
        return true;
      } else {
        return false;
      }
    },
    fecharModalPortador() {
      this.portador.show = false;
      this.portador.item = "";
    },
    getMoreProducts(item) {
      if (item.cupom || item.status != "RE") {
        let link = this.$superingresso.tratarLink(
          typeof item.dscapre == "string" ? item.dscapre : item.dscapre[0]
        );
        this.$router.push({ name: "Produto", params: { id: link } });
        return;
      }

      let idapresentacao =
        typeof item.idapresentacao == "string"
          ? item.idapresentacao
          : item.idapresentacao[0];
      if (this.produtosMore && this.produtosMore.length > 0) {
        this.showAddMore = true;
      } else {
        this.loadMore = true;
        this.showAddMore = true;

        this.$superingresso.getTipoProdutos(idapresentacao).then((response) => {
          if (response && response.tipo_produtos) {
            let tipo_produtos = response;

            tipo_produtos.tipo_produtos.forEach((item_temp) => {
              item_temp.produtos.forEach((item_temp2) => {
                item_temp2.disabledAdd = false;
                item_temp2.disabledMinus = false;
                item_temp2.qtd = 0;
              });
            });

            // MERGE OBJETOS
            if (
              this.$axios.getLocal("carrinho") &&
              this.$axios.getLocal("carrinho").data
            ) {
              let carrinho = this.$axios.getLocal("carrinho").data;
              carrinho.forEach((car) => {
                if (
                  car.idapresentacao &&
                  (car.idapresentacao == idapresentacao ||
                    car.idapresentacao[0] == idapresentacao)
                ) {
                  car.produtos.forEach((prod) => {
                    tipo_produtos.tipo_produtos.forEach((item_temp) => {
                      item_temp.produtos.forEach((item_temp2) => {
                        if (item_temp2.idproduto == prod.idproduto) {
                          ++item_temp2.qtd;
                        }
                      });
                    });
                  });
                }
              });
            }

            tipo_produtos.idapresentacao = idapresentacao;
            this.produtosMore = tipo_produtos;
            // this.$carrinho.atualizaCarrinho()
          }

          this.loadMore = false;
        });
        // return
      }
    },
  },
  mounted() {
    this.atualizaResumo();
  },
};
</script>
<style>
.app-qtd,
.app-qtd > div,
.app-qtd > div > div,
.app-qtd > div > div > div {
  height: 35px !important;
  min-height: 35px !important;
}
.app-qtd > div > div > fieldset {
  height: 47px !important;
  min-height: 47px !important;
}
</style>
<style scoped>
big {
  font-size: 25px;
  color: #27787f;
  font-weight: 900;
}
.modal-produtos-container11 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin: auto 1px;
}
.modal-produtos-container1111 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  /* margin: auto 1px; */
}
.modal-produtos-text03 {
  color: var(--dl-color-principal);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text0333 {
  color: var(--dl-color-principal);
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text04 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text03-1 {
  color: var(--dl-color-principal);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.modal-produtos-text04-1 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.checkout-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eaeaea;
}
.checkout-container01 {
  flex: 0 0 auto;
  width: calc(991px - 40px);
  display: flex;
  margin-top: 30px;
  align-items: center;
  margin-left: 10px;
  padding-top: 10px;
  border-color: #d2232aff;
  border-width: 1px;
  margin-right: 10px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: center;
  background-color: #ffacae;
}
.checkout-icon {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
.checkout-text {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 22px;
  color: #860000;
}
.checkout-container02 {
  flex: 0 0 auto;
  width: 991px;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.checkout-container03 {
  flex: 0 0 auto;
  width: calc(100% - 380px);
  display: flex;
  margin-top: 15px;
  align-items: flex-start;
  margin-right: 30px;
  flex-direction: column;
}
.checkout-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.checkout-text01 {
  color: var(--dl-color-gray-500);
  font-size: px;
}
.checkout-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.checkout-container05-prod {
  width: 563px;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.checkout-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.checkout-container07 {
  width: 100px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: flex-start;
}
.checkout-text02 {
  color: var(--dl-color-gray-white);
  font-size: 34px;
}
.checkout-text03 {
  font-size: 12px;
}
.checkout-text04 {
  color: var(--dl-color-gray-white);
}
.checkout-text05 {
  color: var(--dl-color-gray-white);
  height: 22px;
}
.checkout-container08 {
  width: calc(100% - 40px);
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.checkout-text06 {
  font-size: 24px;
  text-transform: uppercase;
}

.checkout-text06-prod {
  font-size: 16px;
  text-transform: uppercase;
}

.checkout-text07 {
  color: var(--dl-color-gray-700);
  font-size: 12px;
}
.checkout-container09 {
  flex: 0 0 auto;
  width: 30px;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.checkout-container10 {
  height: 60px;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: stretch;
  flex-direction: column;
}
.checkout-icon03 {
  fill: #d9d9d9;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  cursor: pointer;
}
.checkout-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.checkout-container12 {
  flex: 0 0 auto;
  width: calc(33% + 50px);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkout-text09 {
  color: black;
  margin: 10px 0px 0px 0px;
}
div .segDia {
  margin: 10px 0px 10px 0px;
}
.segDia {
  width: 170px;
  padding: 5px;
  background-color: rgb(190, 214, 0);
  border-radius: 5px;
}
.checkout-container13 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.checkout-text10 {
  color: var(--dl-color-gray-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
  text-decoration: line-through;
}
.checkout-text11 {
  color: var(--dl-color-gray-700);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.checkout-text12 {
  color: var(--dl-color-gray-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.modal-produtos-container10 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkout-container14 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.checkout-text13 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}
.checkout-text14 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.checkout-text15 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.checkout-container15 {
  flex: 0 0 auto;
  width: 100px;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.checkout-container15-more {
  flex: 0 0 auto;
  width: 52%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.checkout-container-portador {
  flex: 0 0 auto;
  width: calc(33% + 40px);
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.checkout-container16 {
  /* width: 120px; */
  height: 35px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-color: #ffffff;
  border-width: 1px;
  border-radius: 18px;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #f1f1f1; */
}
.checkout-container17 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkout-container18 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.checkout-icon05 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
.checkout-container19 {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.checkout-container20 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkout-container21 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-principal);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.checkout-icon07 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
.checkout-text18 {
  color: var(--dl-color-principal);
  font-size: 12px;
  align-self: center;
  text-align: center;
  text-decoration: underline;
}
.checkout-text18-close {
  color: var(--dl-color-principal);
  font-size: 12px;
  align-self: center;
  text-align: center;
  text-decoration: underline;
  display: flex;
  justify-content: center;
}
.checkout-text18:hover {
  cursor: pointer;
}
.checkout-text18-close:hover {
  cursor: pointer;
}

.checkout-container18:active {
  background-color: var(--dl-color-secundaria) !important;
  color: white !important;
  width: 27px;
  height: 27px;
}

.checkout-container21:active {
  background-color: var(--dl-color-secundaria) !important;
  color: white !important;
  width: 27px;
  height: 27px;
}

.checkout-container22 {
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  margin-top: 15px;
  align-items: flex-start;
  flex-direction: column;
}

.checkout-text41 {
  font-style: normal;
  font-weight: 700;
}

@media (max-width: 991px) {
  .segDia {
    margin: 15px auto 0px auto !important;
  }
  .checkout-container11,
  .checkout-container15 {
    width: 100% !important;
    display: block;
    text-align: center;
  }
  .checkout-container12,
  .checkout-container14,
  .checkout-container15,
  .checkout-text09,
  .checkout-container-portador,
  .checkout-container15-more {
    width: 100% !important;
    text-align: center;
    padding: 10px;
  }
  .checkout-container15 > div {
    width: 100%;
  }
  .checkout-container15-more {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .checkout-container16 {
    width: 120px !important;
    margin: 0px auto !important;
  }
  .checkout-container01 {
    width: calc(100% - 20px);
    margin-top: 20px;
  }
  .checkout-container05-prod {
    width: 100%;
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .checkout-text {
    font-size: 14px;
  }
  .checkout-container02 {
    width: 100%;
    flex-wrap: wrap;
  }
  .checkout-container03 {
    width: 100%;
    margin-right: 0px;
  }
  .checkout-container06 {
    justify-content: space-between;
  }
  .checkout-container08 {
    align-items: flex-start;
    width: 78%;
  }
  .checkout-container18 {
    position: relative;
    align-self: center;
    border-radius: var(--dl-radius-radius-round);
  }
  .checkout-icon05 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .checkout-container21 {
    width: 30px;
    height: 30px;
    position: relative;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .checkout-icon07 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: auto;
    right: 3px;
    bottom: 3px;
    position: absolute;
    align-self: center;
  }
  .checkout-container22 {
    width: 100%;
  }
  .checkout-container42 {
    justify-content: center;
  }
  .checkout-container45 {
    display: none;
  }
  .checkout-container52 {
    width: 910px;
  }
  .checkout-container55 {
    align-self: center;
  }

  .checkout-container-portador {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .checkout-container15 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .checkout-container15-more {
    flex: 0 0 auto;
    width: 30px;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .checkout-container04 {
    width: 100%;
  }
  .checkout-container05 {
    width: 100%;
  }
  .checkout-text09 {
    color: var(--dl-color-gray-700);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .checkout-container17 {
    width: 30px;
  }
  .checkout-container18 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .checkout-icon05 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .checkout-container20 {
    width: 30px;
  }
  .checkout-container21 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .checkout-icon07 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .checkout-container23 {
    width: 100%;
  }
  .checkout-container24 {
    width: 100%;
  }
  .checkout-text21 {
    font-size: 12px;
  }
  .checkout-text25 {
    font-size: 12px;
  }
  .checkout-text29 {
    font-size: 12px;
  }
  .checkout-text33 {
    font-size: 12px;
  }
  .checkout-text37 {
    font-size: 12px;
  }
  .checkout-text43 {
    color: var(--dl-color-gray-white);
  }
  .checkout-text45 {
    color: var(--dl-color-gray-white);
  }
  .checkout-text47 {
    color: var(--dl-color-gray-white);
  }
  .checkout-container-portador {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .checkout-container15 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
@media (max-width: 479px) {
  .checkout-container10 {
    position: absolute;
    margin-top: 20px;
    display: block;
    left: 80%;
  }
  .checkout-container04 {
    width: 100%;
  }
  .checkout-container05 {
    width: 100%;
  }
  .checkout-container07 {
    width: 72px;
  }
  .checkout-container08 {
    width: 79%;
  }
  .checkout-text06 {
    font-size: 14px;
  }
  .checkout-text07 {
    font-size: 10px;
  }
  .checkout-text10 {
    align-self: flex-start;
  }
  .checkout-text11 {
    align-self: flex-start;
  }
  .checkout-text12 {
    align-self: flex-start;
  }
  .checkout-text13 {
    align-self: flex-start;
  }
  .checkout-text14 {
    align-self: flex-start;
  }
  .checkout-text15 {
    align-self: flex-start;
  }
  .checkout-container23 {
    width: 100%;
  }
  .checkout-container24 {
    width: 100%;
  }
  .checkout-container41 {
    flex-wrap: wrap;
  }
  .checkout-container42 {
    flex-wrap: wrap;
  }
  .checkout-button {
    width: 100%;
  }
  .checkout-text42 {
    color: var(--dl-color-gray-white);
  }
  .checkout-container55 {
    align-self: center;
  }
  .checkout-container10 {
    margin-top: 0px;
  }
}
</style>
